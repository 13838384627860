import {
	getCountryList,
	getCodeByPhone,
	phoneVerifyCode
} from '@/api/index.js';

import {
	Field,
	CountDown,
	Cell,
	Search,
  Icon,
} from 'vant';
// import {isWeiXin} from '@/utils/utils.js';
import localStore from 'storejs';

export default {
	components: {
		[Field.name]: Field,
		[CountDown.name]: CountDown,
		[Cell.name]: Cell,
		[Icon.name]: Icon,
		[Search.name]: Search,
	},
	data() {
		return {
			locationCode: '', // 国家区号
			locationName: '', // 国家名称
			locationOptions: [], // 国家列表
			locationsArray: [],
			phone: '',
			codeVal: '', // 168168
			isSend: false, // 是否已经发送验证码
			time: 60 * 1000, // 倒计时时长
			isShow: false, // 是否显示国家地区列表
			keyword: '',
			bgSrc: require('@/assets/img/home-bg.png'),
			flowTypeSrc: require('@/assets/img/registration.png'),
			txt: '',
      isShowErrTips: false, // 是否显示错误tips
      isShowTips: true, // 是否显示tips
      needPay: 0, // 1-现场登记，0-预登记
		}
	},
	created() {
		document.title = '深圳家居设计周暨深圳国际家具展'
		this.needPay = this.$route.query.needPay;
	},
	methods: {
		// 设置默认地区
		setDefaultLocation() {
			this.locationCode = '86'; // 国家区号
			this.locationName = '中国大陆'; // 国家名称
		},
		// 获取国家列表
		getCountryList(lang) {
			let countryList = localStore('countryList'); // 从本地存储取国家列表
			if (countryList && countryList.length > 0) {
				this.setCountryList(countryList, lang)
			} else {
				getCountryList().then(res => {
					if(res.code == 200) {
						countryList = res.data || [];
						localStore.set('countryList', countryList); // 本地存储取国家列表
						
						this.setCountryList(countryList, lang);
					}
					
				}).catch(err => {
					// console.log(err);
				});
			}
		},
		// 设置国家地区列表
		setCountryList(arr, lang) {
			// 英文版过滤掉国家地区列表第一项: '中国大陆'
			// if (lang == 'en') {
			// 	arr.shift();
			// }
			
			this.locationOptions = arr;
			this.locationsArray = Object.freeze(arr);
		},
		// 显示国家地区列表
		showCountry() {
			this.isShow = true;
			this.keyword = '';
			this.locationOptions = this.locationsArray;
		},
		// 关闭国家地区列表
		close() {
			this.isShow = false;
		},
		// 输入搜索
		inputSearch() {
      if (this.keyword) {
        this.locationOptions = this.locationsArray.filter( e => e.area_name.toLowerCase().includes(this.keyword.toLowerCase()) || e.national_code.toString().includes(this.keyword))
      } else {
        this.locationOptions = this.locationsArray;
      }
		},
		// 国家地区列表选择事件
		chooseLocation(e) {
			let {code, name} = e.currentTarget.dataset;
			
			this.locationCode = code; // 电话区号
			this.locationName = name; // 国家名称
			
			this.popupShow = false
			this.close()
		},
		// 校验手机号
		validatePhone() {
			if (!this.locationCode) {
				this.$toast(this.lang === 'en' ? 'Please select a region' : '请选择地区');
				return true
			}
			
			if(!this.phone) {
				this.$toast(this.lang === 'en' ? 'Please enter your mobile phone number' : '请输入手机号');
				return true;
			}
			if (this.locationCode == 86 && !/1\d{10}/.test(this.phone)) {
				this.$toast(this.lang === 'en' ? 'Please enter the correct mobile phone number' : '请输入正确的手机号');
				// this.$refs.phone.focus();
				return true;
			}
			return false
		},
		// 校验验证码
		validateCode() {
			if (!this.codeVal) {
				this.$toast(this.lang === 'en' ? 'Enter code' : '请输入验证码');
				// this.$refs.codeVal.focus();
				return true;
			}
			return false
		},
		// 发送验证码
		sendCode() {
			if (this.validatePhone()) return;
	
			this.isSend = true;
			let params = {
				idd_code: this.locationCode,
				idd_name: this.locationName,
				phone: this.phone
			};
			getCodeByPhone(params).then( () => {
				this.$toast(this.lang === 'en' ? 'Code sent' : '验证码已发送!');
				this.$refs.countDown.start(); // 开始倒计时
			})
		},
		// 下一步
		nextStep() {
			// 校验所有填写项是否合格
			if (this.validatePhone() || this.validateCode()) 
				return;
			
			this.$toast.loading({
				loadingType: 'spinner',
				forbidClick: true,
				duration: 0,
			});
			
			let params = {
				// browserType: isWeiXin() ? 2 : 3, // 浏览器类型: 1-微信小程序, 2-微信浏览器, 3-其他
				browserType: 2, // 浏览器类型: 1-微信小程序, 2-浏览器
				phone: this.phone,
				codeVal: this.codeVal,
				unionid: this.unionid,
				l: this.l,
				idd_name: this.locationName,
				idd_code: this.locationCode,
				lang: this.lang == 'en' ? 1 : 0, // 语言: 0-中文, 1-英文
			};
			phoneVerifyCode(params).then(res => {
        if(res.code == 200) {
          if (res.data) {
            // 替换本地缓存内 unionid
            console.log(res.data)
            res.data.unionid && localStorage.setItem('unionid', res.data.unionid);
            
            this.pageSwitch(res.data);
          } else {
            this.$toast(this.lang === 'en' ? 'data is null' : '返回的数据为null');
          }
        } else {
          this.$toast.clear();
          
          // 曾用微信小程序报名
          if (res.code === 'ERR_40009') {
            this.isShowErrTips = true;
          }
        }
			}).catch(err => {
				// console.log(err);
				err ? this.$toast(err.message) : this.$toast.clear();
			});
		},
    closeBindTips() {
      this.isShowErrTips = false
      this.isShowTips = false
    }
	},
}
